import { Fragment, type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type APISchemas } from 'src/api/api'
import { useDeleteMainPlan, useEditMainPlan } from 'src/api/plan'
import { type TermSeason } from 'src/api/schema'
import { useTerm, useTermQuarter } from 'src/api/term'
import { addCourseToCalendar, removeCourseFromCalendar } from 'src/AppRoot/store'
import type { CuiDropResult } from 'src/cui/components'
import { CuiDragDropContext, CuiFlexGrid, CuiFlexGroup, CuiPad, CuiText } from 'src/cui/components'
import styled from 'styled-components'

import { PlanQuarter } from './PlanQuarter'

export const PlannerPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.lighterShade};
  height: 100%;
`

export const PlannerHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.background};
`

export type PlannerHeaderProps = {
  /**
   *
   * header search value
   */
  searchValue: string

  /**
   *
   */
  onSearchValueChange: (value: string) => void
}

const PlannerHeader: FunctionComponent = () => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad leftSize='m' verticalSize='l'>
        <CuiFlexGroup alignItems='center'>
          <CuiPad rightSize='xl'>
            <CuiText size='title2'>Your 4-Year Plan</CuiText>
          </CuiPad>
        </CuiFlexGroup>
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

type PlannerBodyProps = {
  plans: any
}

export type TermCourses = {
  season: string
  courses: APISchemas['SavedCourse'][]
  endYear: string
}

type PlannedCourses = {
  [season in TermSeason]: { courses: APISchemas['SavedCourse'][]; id?: string }
}

interface YearPlan {
  [endYear: number]: PlannedCourses
}

const PlannerBody: FunctionComponent<PlannerBodyProps> = ({ plans }) => {
  if (!plans) {
    return null
  }

  return (
    <CuiPad size='m'>
      {Object.keys(plans).map((endYear) => {
        const plan = plans[endYear]
        const planName = `${Number(endYear) - 1} - ${endYear.slice(2)}`

        return (
          <Fragment key={`year-${endYear}`}>
            <CuiPad bottomSize='s'>
              <CuiText size='title4'>{planName}</CuiText>
            </CuiPad>
            <CuiPad bottomSize='m'>
              <CuiFlexGrid columns={4}>
                {Object.keys(plan).map((season) => {
                  return (
                    <PlanQuarter
                      id={`${endYear},${season},${plan[season].id}`}
                      key={`${endYear}-${season}`}
                      season={season}
                      termCourses={plan[season]}
                    />
                  )
                })}
              </CuiFlexGrid>
            </CuiPad>
          </Fragment>
        )
      })}
    </CuiPad>
  )
}

const PlannerImpl = () => {
  const dispatch = useDispatch()
  const { mutateAsync: deleteCourse } = useDeleteMainPlan()
  const { mutateAsync: addToCalendar } = useEditMainPlan()
  const { mutateAsync: getTerm } = useTermQuarter()
  const { mutateAsync: getTermId } = useTerm()
  const data = useSelector((state: any) => state.calendar.calendarData)
  const sunetId = useSelector((state: any) => state.user.sunetId)
  const [plannerData, setPlannerData] = useState<YearPlan>({})
  const hasTransformed = useRef(false)

  const fetchTermId = useCallback(
    async (year: number) => {
      try {
        const response = await getTermId({ params: { endYear: year } })

        return response
      } catch (error) {
        console.error('Error fetching term ID:', error)
      }
    },
    [getTermId]
  )

  const transformPlannerData = useCallback(
    async (courses: APISchemas['SavedCourse'][]) => {
      const plans: YearPlan = {}

      for (const course of courses) {
        try {
          const termDetails = await getTerm({ params: { id: course.termAddedId } })
          const { season, endYear } = termDetails
          const termIds = await fetchTermId(endYear)
          if (!plans[endYear]) {
            plans[endYear] = {
              Autumn: { courses: [], id: termIds?.autumn.id },
              Winter: { courses: [], id: termIds?.winter.id },
              Spring: { courses: [], id: termIds?.spring.id },
              Summer: { courses: [], id: termIds?.summer.id },
            }
          }

          plans[endYear][season].courses.push(course)
        } catch (error) {
          console.error('Error fetching term details for:', course.termAddedId, error)
        }
      }

      setPlannerData(plans)
    },
    [getTerm, fetchTermId]
  )

  useEffect(() => {
    if (!hasTransformed.current && data.length > 0) {
      transformPlannerData(data)
      hasTransformed.current = true
    }
  }, [data, transformPlannerData])

  const handleMoveCourse = async ({
    course,
    toTermId,
    sunetId,
  }: {
    course: APISchemas['SavedCourse']
    toTermId: string
    sunetId: string
  }) => {
    try {
      await deleteCourse(
        { sunetId: sunetId ?? 'hhannah', savedcourseid: course.id },
        {
          onSuccess: () => {
            dispatch(removeCourseFromCalendar(course))
          },
        }
      )

      await addToCalendar(
        {
          params: { sunetId: sunetId ?? 'hhannah' },
          body: {
            metaOfferingId: course.metaOffering.id,
            termAddedId: toTermId,
          },
        },
        {
          onSuccess: () => {
            dispatch(addCourseToCalendar(course))
          },
        }
      )
    } catch (error) {
      console.error('Failed to move course:', error)
    }
  }

  const onDragEnd = (result: CuiDropResult) => {
    const { destination, source } = result
    if (!destination) {
      return
    }

    const [sourceYear, sourceSeason, sourceTermId] = source.droppableId.split(',')
    const [destYear, destSeason, destTermId] = destination.droppableId.split(',')
    if (source.droppableId === destination.droppableId && sourceTermId === destTermId) {
      return
    }

    const sourceYearNum = parseInt(sourceYear)
    const destYearNum = parseInt(destYear)

    setPlannerData((prevState) => {
      const newState = { ...prevState }
      const sourceCourses = [...newState[sourceYearNum][sourceSeason as TermSeason].courses]
      const destCourses = [...newState[destYearNum][destSeason as TermSeason].courses]
      const movedCourse = sourceCourses[source.index]

      sourceCourses.splice(source.index, 1)

      if (source.droppableId === destination.droppableId) {
        sourceCourses.splice(destination.index, 0, movedCourse)
      } else {
        destCourses.splice(destination.index, 0, movedCourse)

        handleMoveCourse({
          course: movedCourse,
          toTermId: destTermId,
          sunetId,
        })
      }

      newState[sourceYearNum][sourceSeason as TermSeason].courses = sourceCourses
      if (source.droppableId !== destination.droppableId) {
        newState[destYearNum][destSeason as TermSeason].courses = destCourses
      }

      return newState
    })
  }

  return (
    <PlannerPageWrapper>
      <CuiDragDropContext onDragEnd={onDragEnd}>
        <PlannerHeader />
        <PlannerBody plans={plannerData} />
      </CuiDragDropContext>
    </PlannerPageWrapper>
  )
}

export const Planner = {
  Planner: PlannerImpl,
}
