import { type FunctionComponent, type PropsWithChildren } from 'react'
import { type CuiThemeColor } from 'src/cui/themes/types'
import styled from 'styled-components'

import { CuiIcon, type CuiIconType } from '../CuiIcon'
import { CuiText } from '../CuiText'

export type CuiBadgeColor = 'normal' | 'warning' | 'constructive' | 'destructive'
type CuiBadgeIconSide = 'left' | 'right'

type ColorMap = {
  [color in CuiBadgeColor]: {
    text: CuiThemeColor
    background: CuiThemeColor
  }
}

const colorMap: ColorMap = {
  normal: {
    text: 'text',
    background: 'lightShade',
  },
  warning: {
    text: 'warning',
    background: 'lightWarning',
  },
  constructive: {
    text: 'constructive',
    background: 'lightConstructive',
  },
  destructive: {
    text: 'destructive',
    background: 'lightDestructive',
  },
}

/**
 * Props for <CuiBadge>.
 */
type Props = {
  /**
   * The color of the badge.
   *
   * @default: 'normal'
   */
  color?: CuiBadgeColor

  /**
   * The icon type.
   */
  iconType?: CuiIconType

  /**
   * The side the icon is on.
   *
   * @default 'left'
   */
  iconSide?: CuiBadgeIconSide
}

type CuiBadgeRootProps = {
  $color: CuiBadgeColor
}

const CuiBadgeRoot = styled.div<CuiBadgeRootProps>`
  padding: 5px 12px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: ${({ $color, theme }) => theme.cuiColors[colorMap[$color].text]};
  background-color: ${({ $color, theme }) => theme.cuiColors[colorMap[$color].background]};
`

type CuiIconBadgeRootProps = {
  $iconSide: CuiBadgeIconSide
}

const CuiIconBadgeRoot = styled.div<CuiIconBadgeRootProps>`
  display: inline-flex;
  align-items: center;
  margin-${({ $iconSide }) => ($iconSide === 'left' ? 'right' : 'left')}: 4px;
`

export const CuiBadge: FunctionComponent<PropsWithChildren<Props>> = ({
  color = 'normal',
  iconType,
  iconSide = 'left',
  children,
}) => {
  const icon = iconType ? (
    <CuiIconBadgeRoot $iconSide={iconSide}>
      <CuiIcon size='s' type={iconType} />
    </CuiIconBadgeRoot>
  ) : undefined

  return (
    <CuiBadgeRoot $color={color}>
      {iconSide === 'left' && icon}
      <CuiText color='inherit' size='title4'>
        {children}
      </CuiText>
      {iconSide === 'right' && icon}
    </CuiBadgeRoot>
  )
}
