import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { type APISchemas } from 'src/api/api'
import { friendlyAttendanceText } from 'src/api/course'
import { CuiFlexGroup, CuiIconPopover, CuiSpacer, CuiText } from 'src/cui/components'

import { CourseDetailedSchedule } from './CourseDetailedSchedule'

type Props = { course: APISchemas['MetaOffering'] }

export const CourseSchedule: FunctionComponent<PropsWithChildren<Props>> = ({ course }) => {
  const { attendance } = course

  return (
    <Fragment>
      <CuiText size='title2'>Current academic year</CuiText>
      <CuiSpacer size='m' />
      <CuiFlexGroup gutterSizeHorizontal='xxl'>
        <CuiFlexGroup direction='column' alignItems='flexStart'>
          <CuiFlexGroup
            gutterSizeHorizontal='s'
            gutterSizeVertical='xs'
            direction='row'
            alignItems='center'
            justifyContent='center'
          >
            <CuiText size='title3'>Attendance</CuiText>
            <CuiIconPopover
              content="This is the type of attendance the course requires. For example, 'In Person' means the course requires students to attend in person."
              iconType='info'
              side='bottom'
              align='end'
            />
          </CuiFlexGroup>
          <CuiText size='paragraph1'>{friendlyAttendanceText[attendance.value]}</CuiText>
        </CuiFlexGroup>
        <CuiFlexGroup direction='column' alignItems='flexStart'>
          <CuiText size='title3'>Enrollment</CuiText>
          <CuiText size='paragraph1'>Coming Soon</CuiText>
        </CuiFlexGroup>
      </CuiFlexGroup>
      <CuiSpacer size='s' />
      <CourseDetailedSchedule course={course} />
    </Fragment>
  )
}
