import React, { type FunctionComponent } from 'react'
import { CuiFlexGroup, CuiSpacer, CuiText } from 'src/cui/components'
import styled from 'styled-components'

const ProfileWrapper = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid;
  border-color: ${({ theme }) => theme.cuiColors.text};
  border-width: 1px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover02};
  }
`

const ProfileCircleSmall = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme.cuiColors.accent};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  aspect-ratio: 1;
  margin-right: 15px;
`

const ProfileCircleLarge = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme.cuiColors.accent};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 50px;
  margin-right: 10px;
`

const ProfileInfoWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh;
`

const NameandUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5vh;
`

const ProfileInfoTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

interface ProfileInfoProps {
  initials: string
  name: string
  sunetid: string
  year: string
  major: string
}

export const ProfileInfo: FunctionComponent<ProfileInfoProps> = ({
  initials,
  name,
  sunetid,
  year,
  major,
}) => (
  <CuiFlexGroup direction='column'>
    <ProfileInfoWrapper>
      <ProfileInfoTop>
        <ProfileCircleLarge>
          <CuiText color='textOnDarkBackground' size='title2'>
            {initials}
          </CuiText>
        </ProfileCircleLarge>
        <NameandUser>
          <CuiText color='text' size='title3'>
            {name}
          </CuiText>
          <CuiText color='text' size='paragraph2'>
            {sunetid}
          </CuiText>
        </NameandUser>
      </ProfileInfoTop>
      <CuiSpacer size='s' />
      <CuiText color='text' size='paragraph2'>
        {year} | {major}
      </CuiText>
    </ProfileInfoWrapper>
    <CuiSpacer as='hr' size='none' />
  </CuiFlexGroup>
)

export { ProfileCircleSmall, ProfileWrapper }
