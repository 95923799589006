import { type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiFlexGroup, CuiSpacer, CuiText } from 'src/cui/components'

import { Facts } from './Facts'
import { StaticNavbar } from './StaticNavbar'

const Hero: FunctionComponent<PropsWithChildren<unknown>> = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CuiFlexGroup direction='column'>
        <div style={{ maxWidth: '760px' }}>
          <CuiText size='titleDisplay'>Make the most of your Stanford experience.</CuiText>
        </div>

        <CuiSpacer size='l' />

        <div style={{ maxWidth: '564px' }}>
          <CuiText size='paragraph1'>
            Carta helps students make data-driven decisions to craft their educational paths.
          </CuiText>
        </div>
      </CuiFlexGroup>
    </div>
  )
}

export const LandingPage: FunctionComponent<PropsWithChildren<unknown>> = () => {
  return (
    <CuiFlexGroup direction='column' gutterSizeVertical='s'>
      <div style={{ width: '100%' }}>
        <StaticNavbar style='light' />
        <Hero />
        <Facts />
      </div>
    </CuiFlexGroup>
  )
}
