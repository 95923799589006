import { type MakeGenerics, useNavigate, useSearch as useSearchParams } from '@tanstack/react-location'
import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { stringifyCourseCode, useSearch } from 'src/api/course'
import { useCreateMainPlan, useMainPlan } from 'src/api/plan'
import {
  CuiButton,
  CuiFeedback,
  CuiFlexGroup,
  CuiPad,
  CuiProfileDropdown,
  CuiSearch,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import styled from 'styled-components'
import { useDebouncedCallback as useDebounce } from 'use-debounce'

import { type SearchType } from './Home/CourseFiltersGroup'
import { ProfileInfo } from './Home/ProfileInfo'
import { loggedInUser } from './Home/ProfileToggle'

const PagePadding = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

const debounceInterval = 300

const CoursesRightBorder = styled.div`
  border-left: 1px solid ${({ theme }) => theme.cuiColors.lightShade};
  box-sizing: border-box;
  width: 100%;
`

const FeedbackButton = styled.div`
  border: none;
  background-color: transparent;
`

const PagesTab = styled(CuiButton)`
  border: none;
`

// TODO: figure out where this goes -- duplicated in Home.tsx
type SearchRouteParams = MakeGenerics<{
  Search: SearchType
}>

export const DashboardContainer: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const selectedOptions = useSearchParams<SearchRouteParams>()

  const [searchValue, setSearchValue] = useState(selectedOptions?.query ?? '')
  const [debouncedSearchValue, setDebouncedValue] = useState(searchValue)
  const { data } = useSearch({ year: 2024 }, { query: debouncedSearchValue }, { enabled: !!searchValue })
  const [isFeedbackVisible, setFeedbackVisible] = useState(false)
  const sunetId = useSelector((state: any) => state.user.sunetId)

  const profileOptions = [
    {
      id: 'userprofile',
      label: 'User Profile',
      component: (
        <ProfileInfo
          initials={sunetId[0]?.toUpperCase() ?? 'HL'}
          name={sunetId?.toUpperCase() ?? 'Hannah Lee'}
          sunetid={sunetId ?? 'hhannah'}
          year='Coterm'
          major='Computer Science'
        />
      ),
    },
    {
      id: 'feedback',
      label: 'Give Feedback',
      component: (
        <FeedbackButton onClick={() => setFeedbackVisible(false)}>
          <CuiText>Give Feedback</CuiText>
        </FeedbackButton>
      ),
    },
    'Dark Mode',
    'Logout',
  ]

  const { data: mainPlan, isLoading, isError } = useMainPlan({ sunetId })
  const { mutate: createMainPlan } = useCreateMainPlan()

  useEffect(() => {
    if (!isLoading && (isError || !mainPlan)) {
      createMainPlan(sunetId)
    }
  }, [sunetId, isLoading, isError, mainPlan, createMainPlan])

  // TODO: we probably want to debounce the actual search function used inside the hook
  const setSearchValueDebounced = useDebounce(() => {
    setDebouncedValue(searchValue)
  }, debounceInterval)

  const onChange = (value: string) => {
    setSearchValue(value)
    setSearchValueDebounced()
  }

  const navigate = useNavigate()

  const onSearch = () => {
    navigate({
      search: (prevSearchParams) => ({
        ...prevSearchParams,
        query: searchValue,
      }),
    })
  }

  return (
    <CoursesRightBorder>
      <PagePadding>
        <CuiPad horizontalSize='s' verticalSize='s'>
          <CuiFlexGroup justifyContent='spaceBetween'>
            <CuiSearch
              items={data?.entries ?? []}
              onChange={onChange}
              onResultClick={(result) => {
                navigate({ to: result.id })
              }}
              getItemHref={(item) => item.id}
              onSearch={onSearch}
              value={searchValue}
              getItemTitle={(item) => `${stringifyCourseCode(item.courseCode)} ${item.title}`}
              getItemIcon={() => 'book'}
              emptyResultsMessage='No results found'
              placeholder='Search anything...'
            />
            <CuiFlexGroup gutterSizeHorizontal='l'>
              <PagesTab onClick={() => navigate({ to: '/planner' })}>
                <CuiText color='accent' size='title3'>
                  4-Year Planner
                </CuiText>
              </PagesTab>
              <PagesTab onClick={() => navigate({ to: '/saved' })}>
                <CuiText color='accent' size='title3'>
                  Saved Courses
                </CuiText>
              </PagesTab>
              <CuiProfileDropdown
                options={profileOptions}
                trigger={loggedInUser}
                getOptionSelect={(option) => {
                  typeof option === 'object' && option.id === 'feedback' && setFeedbackVisible(true)
                }}
                getOptionKey={(option) => (typeof option === 'object' ? option.id : option)}
                getOptionLabel={(option) => (typeof option === 'object' ? option.label : option)}
              />
            </CuiFlexGroup>
          </CuiFlexGroup>
        </CuiPad>
        <div style={{ width: 'calc(100% + 320px)', marginLeft: '-320px' }}>
          <CuiSpacer as='hr' size='none' />
        </div>
        {isFeedbackVisible && <CuiFeedback onClick={() => setFeedbackVisible(false)} />}
        {children}
      </PagePadding>
    </CoursesRightBorder>
  )
}
