import { forwardRef, type FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  CuiBadge,
  CuiDraggable,
  CuiDroppable,
  CuiFlexGroup,
  CuiIcon,
  CuiPad,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { type CuiBadgeColor, type CuiIconType } from 'src/cui/components'
import { quarters } from 'src/utils/calendarFunctions'
import styled, { useTheme } from 'styled-components'

import type { TermCourses } from '.'
import { CoursePlan } from './CoursePlan'

const PlanQuarterWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.cuiColors.background};
  box-shadow: 0px 0px 4px ${({ theme }) => theme.cuiColors.boxShadow};
  border-radius: 8px;
`

const CourseListWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
`

const CourseList = forwardRef<HTMLDivElement, any>(({ children }, ref) => (
  <CourseListWrapper ref={ref}>{children}</CourseListWrapper>
))

const CoursePlanWrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
`

const QuarterSummaryWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
`
const BadgeText = styled.div<{ color: string }>`
  font-weight: 600;
  color: ${({ color }) => color};
  letter-spacing: 1px;
  font-size: 14px;
`

type CourseListItemProps = {
  course: any
  index: number
}

const CourseListItem: FunctionComponent<CourseListItemProps> = ({ course, index }) => {
  const cartaTheme = useTheme()

  return (
    <CuiDraggable draggableId={course.id} index={index}>
      {(provided) => (
        <CoursePlanWrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <CoursePlan
            // @ts-ignore
            course={course.metaOffering}
            color={cartaTheme.cuiColors.darkAccent}
            isTaken={false}
            onCheck={() => {}}
            onDelete={() => {}}
          />
        </CoursePlanWrapper>
      )}
    </CuiDraggable>
  )
}

const CourseListPlaceholder = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.cuiColors.background};
  border-radius: 4px;
  padding: 4px;
  border: 1px dashed ${({ theme }) => theme.cuiColors.darkShade};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  :hover {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.cuiColors.boxShadow};
    background: ${({ theme }) => theme.cuiColors.background};
  }
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.cuiColors.darkShade};
`

type Props = {
  id: string
  season: string
  termCourses: TermCourses
}

export const PlanQuarter: FunctionComponent<Props> = ({ id, season, termCourses }) => {
  const totalUnits = useMemo(() => getTotalUnits(termCourses.courses), [termCourses.courses])
  const globalQuarter = useSelector((state: any) => state.calendar.globalQuarter)
  const globalYear = useSelector((state: any) => state.calendar.globalYear)
  const endYear = parseInt(id.split(',')[0])

  let label: 'Upcoming' | 'Current' | 'Taken' = 'Upcoming'
  if (quarters.indexOf(season) === globalQuarter && endYear === globalYear) {
    label = 'Current'
  } else if (endYear < globalYear) {
    label = 'Taken'
  }

  const status = {
    Upcoming: { color: 'normal', icon: '' },
    Current: { color: 'constructive', icon: 'calendar' },
    Taken: { color: 'normal', icon: 'check' },
  }

  return (
    <PlanQuarterWrapper>
      <CuiPad size='m'>
        <CuiPad bottomSize='s'>
          <CuiFlexGroup justifyContent='spaceBetween'>
            <CuiText size='title4'>{season}</CuiText>
            <CuiIcon type='ext-link' size='m' />
          </CuiFlexGroup>
        </CuiPad>
        <CuiFlexGroup direction='column' gutterSizeVertical='l'>
          <CuiDroppable droppableId={id}>
            {(provided) => (
              <CourseList ref={provided.innerRef} {...provided.droppableProps}>
                {termCourses.courses.map((course, index) => (
                  <CourseListItem key={course.id} course={course} index={index} />
                ))}
                <CourseListPlaceholder>DRAG COURSE HERE</CourseListPlaceholder>
              </CourseList>
            )}
          </CuiDroppable>
        </CuiFlexGroup>
        <CuiSpacer size='s' />
      </CuiPad>
      <QuarterSummaryWrapper>
        <CuiText size='paragraph2' color='hushed'>
          {totalUnits} units
        </CuiText>
        <CuiBadge
          iconType={status[label].icon as CuiIconType}
          color={status[label].color as CuiBadgeColor}
        >
          <BadgeText color={label === 'Current' ? 'constructive' : 'text'}>
            {label.toUpperCase()}
          </BadgeText>
        </CuiBadge>
      </QuarterSummaryWrapper>
    </PlanQuarterWrapper>
  )

  function getTotalUnits(courses: any[]) {
    let totalMinimum = 0
    let totalMaximum = 0

    courses.forEach((course) => {
      if (course.metaOffering?.units) {
        totalMinimum += course.metaOffering.units.minimum
        totalMaximum += course.metaOffering.units.maximum
      }
    })

    if (totalMinimum === totalMaximum) {
      return totalMinimum
    }

    return `${totalMinimum} - ${totalMaximum}`
  }
}
