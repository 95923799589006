import { useMutation } from '@tanstack/react-query'

import { api, type APISchemas } from './api'

function whoAmI(): Promise<APISchemas['WhoAmI']> {
  return api.get('Auth/WhoAmI').json<APISchemas['WhoAmI']>()
}

export function useWhoAmI() {
  return useMutation(() => whoAmI())
}
