import { Fragment, type FunctionComponent, type PropsWithChildren, useState } from 'react'
import { type APISchemas } from 'src/api/api'
import { seasonAbbreviation } from 'src/api/course'
import { CuiButton, CuiFlexGroup, CuiIcon, CuiPad, CuiSpacer, CuiText } from 'src/cui/components'
import styled from 'styled-components'

import { PlannerHeaderWrapper, PlannerPageWrapper } from '../Planner'
import {
  CoursePlanColor,
  CoursePlanContentWrapper,
  CoursePlanWrapper,
  getNumUnits,
} from '../Planner/CoursePlan'

const SavedListWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  width: 50%;
  height: 100%;
  padding: 24px 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.cuiColors.boxShadow};
`

const SavedListButton = styled(CuiButton)`
  border: none;
  padding: 0px;
  :hover:after {
    background-color: transparent;
  }
`

const SavedBodyWrapper = styled.div`
  padding: 24px;
`

const CourseTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`
type SavedCourseProps = {
  /**
   *
   * The color of the bar on the left
   *
   */
  color: string

  /**
   *
   * The list of courses
   *
   */
  list: any
}

type SavedBodyProps = {
  /**
   *
   * Saved lists
   *
   */
  lists: any
}

const SavedHeader: FunctionComponent = () => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad leftSize='m' verticalSize='l'>
        <CuiFlexGroup alignItems='center'>
          <CuiPad rightSize='xl'>
            <CuiText size='title2'>Saved Courses</CuiText>
          </CuiPad>
        </CuiFlexGroup>
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

const SavedList: FunctionComponent<PropsWithChildren<SavedCourseProps>> = ({ color, list }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)

  return (
    <SavedListWrapper>
      <CuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
        <SavedListButton onClick={() => setIsOpen(!isOpen)}>
          <CuiIcon type='caret-down' size='m' />
          <CuiText size='title3'>{list.name}</CuiText>
        </SavedListButton>
        <SavedListButton
          iconType={isFavorite ? 'star-filled' : 'star-empty'}
          onClick={() => setIsFavorite(!isFavorite)}
        />
      </CuiFlexGroup>
      {isOpen && (
        <Fragment>
          <CuiSpacer size='m' />
          {list.courses.map((course: any) => (
            <CoursePlanWrapper key={course.id}>
              <CoursePlanColor $color={color} />
              <CoursePlanContentWrapper isSavedCourse={true}>
                <CourseTitleWrapper>
                  <CuiText size='label'>
                    {`${course?.metaOffering.courseCode.subject} ${course?.metaOffering.courseCode.catalogNumber}`}
                    &nbsp;
                  </CuiText>
                  <CuiText size='caption' color='hushed'>
                    {course?.metaOffering.title}
                  </CuiText>
                </CourseTitleWrapper>
                <CuiText size='caption' color='hushed' />
                <CuiText size='caption' color='hushed'>
                  {getNumUnits(course.metaOffering)}
                </CuiText>
                <CuiText size='caption' color='hushed'>
                  {course.metaOffering.seasons
                    .map((season: APISchemas['TermSeason']) => seasonAbbreviation[season])
                    .join(', ')}
                </CuiText>
              </CoursePlanContentWrapper>
            </CoursePlanWrapper>
          ))}
        </Fragment>
      )}
    </SavedListWrapper>
  )
}

const SavedBody: FunctionComponent<PropsWithChildren<SavedBodyProps>> = ({ lists }) => {
  return (
    <SavedBodyWrapper>
      {lists.map((list: any) => (
        <SavedList color='red' list={list} key={list.id} />
      ))}
    </SavedBodyWrapper>
  )
}

export const SavedCourses: FunctionComponent = () => {
  // const { data } = usePlan({ plannerid: 'hhannah' })

  const data = [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'Product Design Undergrad',
      courses: [
        {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          term: {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            season: 'Autumn',
            endYear: 0,
          },
          metaOffering: {
            id: '2023:aaaaaaaabbbbccccddddeeeeeeeeeeee:CS100',
            title: 'Programming Methodology',
            description: 'string',
            courseCode: {
              subject: 'CS',
              catalogNumber: '106A',
            },
            crosslistings: [
              {
                id: '2023:aaaaaaaabbbbccccddddeeeeeeeeeeee:CS100',
                courseCode: {
                  subject: 'string',
                  catalogNumber: 'string',
                },
              },
            ],
            repeatability: {
              maxRepeatCount: 0,
              maxRepeatUnits: 0,
            },
            units: {
              minimum: 3,
              maximum: 5,
            },
            attendance: {
              isUnusuallyVarying: true,
              value: 'IndependentStudies',
            },
            requirements: {
              isUnusuallyVarying: true,
              value: ['WayAII'],
            },
            seasons: ['Autumn', 'Winter', 'Spring'],
            gradingBasis: {
              isUnusuallyVarying: true,
              value: 'Letter',
            },
          },
        },
      ],
    },
  ]

  return (
    <PlannerPageWrapper>
      <SavedHeader />
      <SavedBody lists={data} />
    </PlannerPageWrapper>
  )
}
