import { useMutation } from '@tanstack/react-query'

import { api, type APIRoutes, type APISchemas } from './api'

function getTerm(
  params: APIRoutes['/Term/Year/{endYear}']['get']['parameters']['path']
): Promise<APISchemas['TermsInYear']> {
  return api.get(`Term/Year/${params.endYear}`).json<APISchemas['TermsInYear']>()
}

export function useTerm() {
  return useMutation(
    (variables: { params: APIRoutes['/Term/Year/{endYear}']['get']['parameters']['path'] }) =>
      getTerm(variables.params)
  )
}

function getTermQuarter(
  params: APIRoutes['/Term/{id}']['get']['parameters']['path']
): Promise<APISchemas['Term']> {
  return api.get(`Term/${params.id}`).json<APISchemas['Term']>()
}

export function useTermQuarter() {
  return useMutation((variables: { params: APIRoutes['/Term/{id}']['get']['parameters']['path'] }) =>
    getTermQuarter(variables.params)
  )
}
