import { type FunctionComponent, type PropsWithChildren, useMemo } from 'react'
import { type APISchemas } from 'src/api/api'
import { CuiIcon, CuiText } from 'src/cui/components'
import styled from 'styled-components'

type CoursePlanWrapperProps = {
  $isChecked?: boolean
}

export const CoursePlanWrapper = styled.div<CoursePlanWrapperProps>`
  display: flex;
  width: 100%;
  background: ${({ theme, $isChecked }) =>
    $isChecked ? theme.cuiColors.lighterShade : theme.cuiColors.background};
  border-radius: 4px;
  padding-right: 4px;
  border: 0.62px solid ${({ theme }) => theme.cuiColors.darkShade};
  overflow: hidden;
  align-items: center;
  :hover {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.cuiColors.boxShadow};
    background: ${({ theme }) => theme.cuiColors.background};
  }
`

type CourseColorProps = {
  $color: string

  $isChecked?: boolean
}

export const CoursePlanColor = styled.div<CourseColorProps>`
  align-self: stretch;
  width: 4px;
  background: ${({ theme, $color, $isChecked }) => ($isChecked ? theme.cuiColors.darkShade : $color)};
  margin-right: 2px;
  pointer-events: none;
`

export const CoursePlanContentWrapper = styled.div<{ isSavedCourse?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isSavedCourse }) => (isSavedCourse ? 'space-between' : 'flex-start')};
  height: 100%;
  width: 100%;
  padding: ${({ isSavedCourse }) => (isSavedCourse ? '4px 8px' : '4px 0px 4px 8px')};
  gap: 8px;
  pointer-events: none;
`

const CourseTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`
const UnitsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-right: 12px;
`

const DeleteButtonHoverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.cuiColors.overlayHover01};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  :hover {
    box-shadow: none;
  }
`

type Props = {
  /**
   *
   * The course being displayed
   *
   */
  course: APISchemas['MetaOffering']

  /**
   *
   * The color of the bar on the left
   *
   */
  color: string

  /**
   *
   * Whether or not the course is marked as taken, controls whether or not component shows up in greyed out state
   *
   */
  isTaken: boolean

  /**
   *
   * Function passed in to handle course being marked as taken or marked as not taken
   *
   */
  onCheck: ({ checked, course }: { checked: boolean; course: APISchemas['MetaOffering'] }) => void

  /**
   *
   * Function passed in to handle course being deleted from planner
   *
   */
  onDelete: (course: APISchemas['MetaOffering']) => void
}

export function getNumUnits(course: APISchemas['MetaOffering']) {
  let numUnits
  if (!course?.units) {
    return 0
  }

  if (course.units.minimum === course.units.maximum) {
    if (course.units.minimum === 1) {
      numUnits = `${course.units.minimum.toString()} unit`
    } else {
      numUnits = `${course.units.minimum.toString()} units`
    }
  } else {
    numUnits = `${course.units.minimum.toString()}-${course.units.maximum.toString()} units`
  }

  return numUnits
}

export const CoursePlan: FunctionComponent<PropsWithChildren<Props>> = ({
  course,
  color,
  isTaken,
  onDelete,
  // onCheck,
}) => {
  const numUnits = useMemo(() => {
    return getNumUnits(course)
  }, [course])

  return (
    <CoursePlanWrapper $isChecked={isTaken}>
      <CoursePlanColor $color={color} $isChecked={isTaken} />
      <CoursePlanContentWrapper>
        <CourseTitleWrapper>
          <CuiText size='label'>{`${course?.courseCode.subject} ${course?.courseCode.catalogNumber}`}</CuiText>
        </CourseTitleWrapper>
        <UnitsWrapper>
          <CuiText size='caption' color='hushed'>
            {numUnits}
          </CuiText>
        </UnitsWrapper>
      </CoursePlanContentWrapper>
      <ButtonsWrapper>
        <DeleteButtonHoverWrapper onClick={() => onDelete(course)}>
          <CuiIcon type='trash' size='s' />
        </DeleteButtonHoverWrapper>
        {/* <CuiTooltip
          content={isTaken ? 'Mark as not taken' : 'Mark as taken'}
          side='bottom'
          align='center'
        >
          <CuiCheckbox checked={isTaken} onChange={(checked) => onCheck({ checked, course })} />
        </CuiTooltip> */}
      </ButtonsWrapper>
    </CoursePlanWrapper>
  )
}
