import { useNavigate } from '@tanstack/react-location'
import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMainPlan } from 'src/api/plan'
import { useAppDispatch } from 'src/AppRoot/hooks'
import { setCalendarData, setGlobalQuarter, setGlobalYear } from 'src/AppRoot/store'
import {
  CuiButton,
  CuiCalendar,
  CuiFlexGroup,
  CuiPad,
  CuiPinnedCourses,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { ReactComponent as CartaLogo } from 'src/images/logos/logo.svg'
import { quarters } from 'src/utils/calendarFunctions'
import styled from 'styled-components'

export const STATIC_SIDEBAR_WIDTH = '320px'
export const STATIC_SIDEBAR_PADDING = '24px'

type CuiCalendarHeaderProps = {
  /**
   *
   * quarter to display
   *
   * @default 0
   */
  quarter: number

  /**
   *
   * year to display
   *
   * @default 0
   */
  year: number

  /**
   *
   * set quarter
   *
   *
   */
  setQuarter: (quarter: number) => void

  /**
   *
   * set year
   *
   *
   */
  setYear: (year: number) => void

  /**
   *
   * dispatch
   *
   *
   */
  dispatch: any
}

export const SidebarRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: ${STATIC_SIDEBAR_WIDTH};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${STATIC_SIDEBAR_PADDING};
  background-color: ${({ theme }) => theme.cuiColors.background};
`

const TitleComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 76px;
  justify-content: flex-start;
  cursor: pointer;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`

const date = new Date()
const currentYear = date.getFullYear()
const currentMonth = date.getMonth()

function getCurrentQuarter(currentMonth: number) {
  if (currentMonth <= 2) {
    return 1
  } else if (currentMonth <= 5) {
    return 2
  } else if (currentMonth <= 7) {
    return 3
  }

  return 0
}

function getCurrentSchoolYear(currentMonth: number) {
  if (currentMonth <= 7) {
    return currentYear
  }

  return currentYear + 1
}

const CuiCalendarHeader: FunctionComponent<PropsWithChildren<CuiCalendarHeaderProps>> = ({
  quarter,
  year,
  setQuarter,
  setYear,
  dispatch,
}) => {
  const handleChevronClick = (direction: 'left' | 'right') => {
    let newQuarter = quarter
    let newYear = year

    if (direction === 'left') {
      newQuarter = quarter === 0 ? 3 : quarter - 1
      newYear = quarter === 0 ? year - 1 : year
    } else {
      newQuarter = quarter === 3 ? 0 : quarter + 1
      newYear = quarter === 3 ? year + 1 : year
    }

    setQuarter(newQuarter)
    setYear(newYear)

    dispatch(setGlobalQuarter(newQuarter))
    dispatch(setGlobalYear(newYear))
  }

  const title = `${quarters[quarter]} ${year - 1}-${year % 2000}`

  return (
    <HeaderTitle>
      <CuiButton iconType='chevron-left' onClick={() => handleChevronClick('left')} />
      <CuiText size='title2'>{title}</CuiText>
      <CuiButton iconType='chevron-right' onClick={() => handleChevronClick('right')} />
    </HeaderTitle>
  )
}

export const Sidebar: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const sunetId = useSelector((state: any) => state.user.sunetId)
  const [quarter, setQuarter] = useState(getCurrentQuarter(currentMonth))
  const [year, setYear] = useState(getCurrentSchoolYear(currentMonth))
  const { data, isLoading, isError } = useMainPlan({ sunetId: sunetId ?? 'hhannah' })
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (data && !isLoading && !isError) {
      dispatch(setCalendarData(data.courses))
    }
  }, [data, isLoading, isError, dispatch])

  const navigate = useNavigate()

  return (
    <SidebarRoot>
      <div style={{ width: '100%' }}>
        <TitleComponent>
          <CartaLogo onClick={() => navigate({ to: '/' })} />
        </TitleComponent>
      </div>
      <CuiSpacer size='xs' />
      <CuiFlexGroup alignItems='center' direction='column'>
        <CuiCalendarHeader
          quarter={quarter}
          setQuarter={setQuarter}
          year={year}
          setYear={setYear}
          dispatch={dispatch}
        />
        <CuiSpacer size='m' />
        <div style={{ alignSelf: 'flex-start' }}>
          <CuiText size='title3'>&nbsp; Planned schedule</CuiText>
        </div>
        <CuiPad verticalSize='xs' />
        <CuiCalendar
          width={50}
          height={28}
          rows={11}
          currentQuarter={quarters[quarter]}
          endYear={year}
        />
        <CuiPad size='s' />
        <CuiPinnedCourses isMajor={false} />
      </CuiFlexGroup>
    </SidebarRoot>
  )
}
