import { combineReducers, configureStore, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

interface CalendarState {
  calendarData: any[][]
  globalQuarter: number
  globalYear: number
}

const initialCalendarState: CalendarState = {
  calendarData: [],
  globalQuarter: 0,
  globalYear: 2025,
}

interface UserState {
  sunetId: string
}

const initialUserState: UserState = {
  sunetId: '',
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: initialCalendarState,
  reducers: {
    setCalendarData(state, action: PayloadAction<any[]>) {
      state.calendarData = action.payload
    },
    addCourseToCalendar(state, action: PayloadAction<any>) {
      state.calendarData.push(action.payload)
    },
    removeCourseFromCalendar(state, action: PayloadAction<{ id: string }>) {
      state.calendarData = state.calendarData.filter((course: any) => course.id !== action.payload.id)
    },
    setGlobalQuarter(state, action: PayloadAction<number>) {
      state.globalQuarter = action.payload
    },
    setGlobalYear(state, action: PayloadAction<number>) {
      state.globalYear = action.payload
    },
  },
})

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setSunetId(state, action: PayloadAction<string>) {
      state.sunetId = action.payload
    },
  },
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['calendar', 'user'],
}

const rootReducer = combineReducers({
  user: userSlice.reducer,
  calendar: calendarSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const {
  setCalendarData,
  addCourseToCalendar,
  removeCourseFromCalendar,
  setGlobalQuarter,
  setGlobalYear,
} = calendarSlice.actions

export const { setSunetId } = userSlice.actions

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export { store }

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
